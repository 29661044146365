import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {Link} from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Sivua ei löytynyt</h1>
    <p>Tulit sivulle, jote ei ole olemassa.</p>
    <Link>Etusivulle →</Link>
  </Layout>
)

export default NotFoundPage
